import React from 'react';

function Slider (props) {

    let change = (event) => {
        props.sliderChanged(event.target.value, props.text);
    }

    return <span>
        {props.text} <input type="range"  min="0" max="5" disabled={props.disabled} onChange={change} value={props.value} /> {props.value}
    </span>
}

export default Slider;