import React, { Component } from 'react';
import Button from './components/button'
//import ErrorButton from './components/errorButton'
import ErrorMessage from './components/errorMessage'
import SweetCoffeeMachine from './mock/sweetCoffeeMock'
import Slider from './components/slider'
import './App.css';

class App extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
			sugarSliderLevel: 0,
			milkSliderLevel: 0,
			choco: true,
			message: "Klaar na bootup",
			disabled : false,
			disabledCappuccino : false,
			errorState: false,
	  	}
	
		this.sweetCoffeeMock = new SweetCoffeeMachine();
		this.sweetCoffeeMock.readyCallback(this.readyCallBack);
	}
	
	readyCallBack = () => {
		  this.setState({message: "klaar voor keuze!", disabled: false});
		  if(this.sweetCoffeeMock.getSugar() === 0 || this.sweetCoffeeMock.getMilk() === 0) {
			  this.setState({disabledCappuccino: true});
		  }
		  this.setState({sugarSliderLevel: 0, milkSliderLevel: 0});
	}

	disabledOrNotCappucino = () => {
		return this.state.disabled || !(this.sweetCoffeeMock.getSugar() > 0 && this.sweetCoffeeMock.getMilk() > 0);
	}
	
	disabledOrNotChoco = () => {
		return this.state.disabled || !this.sweetCoffeeMock.getChoco() > 0;
	}
	
	disabledOrNotSlider = (slider) => {
		if(slider === 'sugar') {
			return this.state.disabled || !this.sweetCoffeeMock.getSugar() > 0;

		} else if(slider === 'milk') {
			return this.state.disabled || !this.sweetCoffeeMock.getMilk() > 0;
		}
	}
	
	makeBeverage = (beverage) => {
		this.setState({disabled: true, message: 'Machine maakt ' + beverage})
		if (beverage === "Americano") {
			this.sweetCoffeeMock.makeAmericano(this.state.sugarSliderLevel, this.state.milkSliderLevel);
		}
		else if (beverage === "Cappuccino") {
			this.sweetCoffeeMock.makeCappoccino(this.state.sugarSliderLevel, this.state.milkSliderLevel);
		}
		else if (beverage === "Choco") {
			this.sweetCoffeeMock.makeChoco(this.state.sugarSliderLevel, this.state.milkSliderLevel);
		}
		else if (beverage === "Wiener melange") {
			this.sweetCoffeeMock.makeWienerMelange(this.state.sugarSliderLevel, this.state.milkSliderLevel);
		}
		else if (beverage === "Black tea") {
			this.sweetCoffeeMock.makeBlackTea(this.state.sugarSliderLevel, this.state.milkSliderLevel);
		}
		else if (beverage === "Earl Gray") {
			this.sweetCoffeeMock.makeEarlGray(this.state.sugarSliderLevel, this.state.milkSliderLevel);
		}
	}

	sliderChanged = (value, slider) => {
		if (slider === 'Suiker' && value <= this.sweetCoffeeMock.getSugar()) {
		  this.setState({sugarSliderLevel: value});
		}
		else if(slider === 'Melk' && value <= this.sweetCoffeeMock.getMilk()) {
			this.setState({milkSliderLevel: value});
		}
	}

	//funtie om een error te triggeren in de mock
	triggerError = () => {
		this.sweetCoffeeMock.errorCallback();

		this.displayError();
	}

	displayError = () => {
		let errorCode = this.sweetCoffeeMock.getErrorState();

		if(errorCode === 1) {
			this.setState({disabled: true, errorState: 'Geen water'});
		} else if(errorCode === 2) {
			this.setState({disabled: true, errorState: 'Interne status fout'});
		} else if(errorCode === 3) {
			this.setState({disabled: true, errorState: 'Temperatuur te laag'});
		}
	}
	
	render() {
		return (
		  	<div className="App">
			  	<div className="buttonsPanel">
					<div className="reservoir">suiker:{this.sweetCoffeeMock.getSugar()} melk:{this.sweetCoffeeMock.getMilk()} choco:{this.sweetCoffeeMock.getChoco()}</div>
					<div className="buttons">
						<Button type="Americano" disabled={this.state.disabled} beverage={this.makeBeverage}/>
						<Button type="Cappuccino" disabled={this.disabledOrNotCappucino()} beverage={this.makeBeverage}/>
						<Button type="Choco" disabled={this.disabledOrNotChoco()} beverage={this.makeBeverage}/>
						<Button type="Wiener melange" disabled={this.disabledOrNotChoco()} beverage={this.makeBeverage}/>
						<Button type="Black tea" disabled={this.state.disabled} beverage={this.makeBeverage}/>
						<Button type="Earl Gray" disabled={this.state.disabled} beverage={this.makeBeverage}/>
					</div>
					<div className="sliders">
						<Slider text="Suiker" disabled={this.disabledOrNotSlider('sugar')} sliderChanged={this.sliderChanged} value={this.state.sugarSliderLevel} />
						<Slider text="Melk" disabled={this.disabledOrNotSlider('milk')} sliderChanged={this.sliderChanged} value={this.state.milkSliderLevel} />
					</div>
					<div className="status">{this.state.message}</div>
					{/* <ErrorButton triggerError={this.triggerError}/> */}
					<ErrorMessage message={this.state.errorState}/>
				</div>
		  	</div>
		);
	  }
	}
	
	export default App;