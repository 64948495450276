import React from 'react';

function Button (props) {
    let beverage = () => {
        props.beverage(props.type);
    }

    return <button className="button" onClick={beverage} disabled={props.disabled} >{props.type}</button>
}

export default Button;