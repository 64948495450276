/**
 * Doel van dit object is het bieden van een interface naar de koffiezetmachine, er is gedrag geimplementeerd
 * echter voel je vrij om dit naar behoefte aan te passen. Zolang de interface van het mock object identiek blijft.
 * 
 * 
 */

export default class SweetCoffeeMachine {


	constructor() {
		this.errorState = 0;
		this.sugar = 5;
		this.milk = 5;
		this.choco = 5;
	}


	getErrorState() {
		return this.errorState;
	}
	setErrorState(errorState) {
		this.errorState = errorState;
	}

	getSugar() {
		return this.sugar;
	}
	setSugar(sugar) {
		this.sugar = sugar;
	}

	getMilk() {
		return this.milk;
	}
	setmilk(milk) {
		this.milk = milk;
	}

	getChoco() {
		return this.choco;
	}
	setChoco(choco) {
		this.choco = choco;
	}

	makeBeverage(sugar, milk, choco) {
		this.setSugar(this.getSugar() - sugar);
		this.setmilk(this.getMilk() - milk);
		this.setChoco(this.getChoco() - choco);
	}

	/*
	 * Sends a command to the coffee machine to start making a drink.
	 * sugar and milk are values between [0,1] 
	 */
	makeAmericano(sugar, milk) {
		if (typeof this._cbReady === 'function') {

			this.makeBeverage(sugar, milk, 0);
			setTimeout(this._cbReady, 2000);
		}
		return true;
	}

	/*
	 * Sends a command to the coffee machine to start making a drink.
	 * sugar and milk are values between [0,1] 
	 */
	makeCappoccino(sugar, milk) {
		if (typeof this._cbReady === 'function') {

			this.makeBeverage(sugar, milk, 0);
			setTimeout(this._cbReady, 2000);
		}
		return true;
	}

	/*
	 * Sends a command to the coffee machine to start making a drink.
	 * sugar and milk are values between [0,1] 
	 */
	makeWienerMelange(sugar, milk) {
		if (typeof this._cbReady === 'function') {

			this.makeBeverage(sugar, milk, 1);
			setTimeout(this._cbReady, 2000);
		}
		return true;
	}

	/*
	 * Sends a command to the coffee machine to start making a drink.
	 * sugar and milk are values between [0,1] 
	 */
	makeChoco(sugar, milk) {
		if (typeof this._cbReady === 'function') {

			this.makeBeverage(sugar, milk, 1);
			setTimeout(this._cbReady, 2000);
		}
		return true;
	}

	/*
	 * Sends a command to the coffee machine to start making a drink.
	 * sugar and milk are values between [0,1] 
	 */
	makeBlackTea(sugar, milk) {
		if (typeof this._cbReady === 'function') {

			this.makeBeverage(sugar, milk, 0);
			setTimeout(this._cbReady, 2000);
		}
		return true;
	}

	/*
	 * Sends a command to the coffee machine to start making a drink.
	 * sugar and milk are values between [0,1] 
	 */
	makeEarlGray(sugar, milk) {
		if (typeof this._cbReady === 'function') {

			this.makeBeverage(sugar, milk, 0);
			setTimeout(this._cbReady, 2000);
		}
		return true;
	}

	/**
	 * Callback for retrieving error messages, takes callback function as parameter. Setting the callback 
	 * tiggers a response in a minute
	 */
	errorCallback(callback) {
		this._cbError = callback;

		let min = Math.ceil(1);
		let max = Math.floor(4);
  		let error = Math.floor(Math.random() * (max - min)) + min;

		this.setErrorState(error);
	}

	/**
	 * Callback for retrieving ready messages, takes callback function as parameter.  Setting the callback 
	 * tiggers a response in a minute
	 */
	readyCallback(callback) {
		this._cbReady = callback;
	}

}